<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backlist">mdi-reply</v-icon>

        {{ "Google Sheet List" }}
      </div>
    </div>
    <v-card>
      <v-card-title class="primary white--text" primary-title>
        Edit Google Sheet
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-switch
                label="Active"
                inset
                v-model="bodyEdit.active"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="4" class="mt-3">
              <v-text-field
                label="lastRow"
                v-model="bodyEdit.lastRow"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="mt-3">
              <v-select
                :items="references"
                v-model="bodyEdit.howKnow"
                label="Source"
                item-text="name"
                item-value="name"
                outlined
                clearable
                dense
              ></v-select>
            </v-col>
          </v-row>
          <template v-if="bodyEdit.matchingSetting">
            <v-divider class="my-1"></v-divider>
            <div class="d-flex justify-center">
              <strong>Match Settings</strong>
            </div>
            <v-divider class="mt-1 mb-2"></v-divider>
            <v-row class="pa-1">
              <v-col cols="12" sm="6">
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.name"
                  label="Name *"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.last_name"
                  label="Last name"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.phone"
                  label="Phone *"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.email"
                  label="Email *"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.created_time"
                  label="Created time"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.campaing"
                  label="Campaing"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.status"
                  label="Status"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.weight"
                  label="Weight"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.height"
                  label="Height"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.birthDate"
                  label="Birth date"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.procedureInterest"
                  label="Procedure of interest"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="bodyEdit.matchingSetting.zipCode"
                  label="Zip code"
                  outlined
                  clearable
                  dense
                ></v-select>
              </v-col>
              <template v-if="firstData != null">
                <v-col class="d-flex flex-column" cols="12" sm="6">
                  <v-text-field
                    label="Name"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.name)"
                  ></v-text-field>
                  <v-text-field
                    label="Last Name"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.last_name)"
                  ></v-text-field>
                  <v-text-field
                    label="Phone"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.phone)"
                  ></v-text-field>
                  <v-text-field
                    label="Email"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.email)"
                  ></v-text-field>
                  <v-text-field
                    label="Created time"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.created_time)"
                  ></v-text-field>
                  <v-text-field
                    label="Campaing"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.campaing)"
                  ></v-text-field>

                  <v-text-field
                    label="Status"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.status)"
                  ></v-text-field>
                  <v-text-field
                    label="Weight"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.weight)"
                  ></v-text-field>
                  <v-text-field
                    label="Height"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.height)"
                  ></v-text-field>
                  <v-text-field
                    label="Birth date"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.birthDate)"
                  ></v-text-field>
                  <v-text-field
                    label="Procedure of interest"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.procedureInterest)"
                  ></v-text-field>
                  <v-text-field
                    label="Zip code"
                    disabled
                    outlined
                    dense
                    :value="getData(bodyEdit.matchingSetting.zipCode)"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="cancelEdit">cancel</v-btn>
        <v-btn
          color="warning"
          :loading="loading"
          :disabled="loading"
          text
          @click="confirmEdit"
          >update</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { notifyError, notifyInfo } from "@/components/Notification";
import cleanObject from "@/utils/cleanObject";
import { getAPI } from "@/api/axios-base";
export default {
  name: "google-sheet-edit",
  data() {
    return {
      loading: false,
      itemEdit: null,
      headersData: null,
      firstData: null,
      bodyEdit: {
        lastRow: 0,
        active: false,
        howKnow: null,
        matchingSetting: {
          name: null,
          last_name: null,
          phone: null,
          email: null,
          created_time: null,
          campaing: null,
          status: null,
          weight: null,
          height: null,
          birthDate: null,
          procedureInterest: null,
          zipCode: null,
        },
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    backlist() {
      localStorage.removeItem("googleItem");
      this.$router.push(`/leads_center/google_sheet_list`);
    },

    toEdit() {
      const item = JSON.parse(localStorage.getItem("googleItem"));
      this.itemEdit = item;
      this.bodyEdit.lastRow = item.lastRow ? item.lastRow : 0;
      this.bodyEdit.active = item.active ? item.active : false;
      this.bodyEdit.howKnow = item.howKnow ? item.howKnow : null;
      this.bodyEdit.matchingSetting = item.matchingSetting;
      this.loadHeaders();
    },
    loadHeaders() {
      this.loading = true;
      getAPI
        .get(
          `/googleapi/getDocInfo/${this.itemEdit.googleId}/${this.itemEdit.sheetName}`
        )
        .then((res) => {
          this.loading = false;
          this.headersData = res.data.headers;
          this.firstData = res.data.first;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getData(value) {
      return this.firstData[value];
    },

    confirmEdit() {
      this.loading = true;
      let body = Object.assign({}, this.bodyEdit);
      body = cleanObject(body);
      if (body.matchingSetting) {
        body.matchingSetting = cleanObject(body.matchingSetting);
      }
      body.lastRow = Number(body.lastRow);
      getAPI
        .put("/googleapi/updateTask/" + this.itemEdit.uuid, body)
        .then(() => {
          notifyInfo("Task has been updated");
          this.cancelEdit();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelEdit() {
      this.itemEdit = null;
      this.loading = false;
      this.bodyEdit = {
        lastRow: 0,
        active: false,
        matchingSetting: {
          name: null,
          last_name: null,
          phone: null,
          email: null,
          created_time: null,
          campaing: null,
          howKnow: null,
          status: null,
          weight: null,
          height: null,
          birthDate: null,
          procedureInterest: null,
          zipCode: null,
        },
      };

      this.backlist();
    },
  },
  mounted() {
    this.actListReferences();
    this.toEdit();
  },
};
</script>
<style lang=""></style>
